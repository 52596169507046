<template>
    <div>
        <span>{{timeString}}</span>
    </div>
</template>

<script>
import common from "@/utils/common";
export default {
    name: "timeCountDown",
    props: {
        leftTime: [String, Number]
    },
    data() {
        return {
            timeString: "",
            instance: null
        }
    },
    created() {
        this.countTime();
    },
    beforeDestroy() {
        clearInterval(this.instance)
    },
    methods: {
        countTime() {
            this.instance = setInterval(() => {
                let left_time = Number(this.leftTime) - 1;
                this.$emit('change', left_time);
                if (left_time < 0) {
                    clearInterval(this.instance);
                }
                this.timeString = common.formatTimeCount(left_time);
            }, 1000);
        }
    }

}
</script>

<style scoped>

</style>